<template>
  <button :type="btnType"
    :disabled="disabled"
    :is-loading="isLoading"
    :class="getClasses"
  >
    <slot></slot>
    <svg v-if="isLoading" class="animate-spin ml-2 h-5 w-5"
         :class="getIconColour" xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </button>
</template>

<script setup lang="ts">
  import { computed } from "vue";

  const props = defineProps({
    btnType: {
      default: 'button',
      type: String
    },
    theme: {
      default: 'primary',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  });

  const getClasses = computed(() => {

    let classes = 'inline-flex items-center justify-center px-6 py-2 min-w-[104px] font-semibold leading-6 text-sm shadow rounded-full transition ease-in-out duration-150 disabled:bg-grey-200 disabled:text-grey-400 disabled:shadow-none disabled:cursor-not-allowed';

    switch (props.theme) {
      case 'primary' :
        classes += ' bg-primary text-white';
      break;
      case 'secondary' :
        classes += ' bg-secondary text-primary';
        break;
      case 'white' :
        classes += ' bg-white text-primary';
        break;
      case 'outline-secondary' :
        classes += ' border-2 border-secondary text-secondary';
        break;
      case 'outline-primary' :
        classes += ' border-2 border-primary text-primary';
        break;
      case 'blank' :
        classes += ' shadow-none py-0 !px-0 !min-w-0';
        break;
    }

    if (props.isLoading) {
        classes += ' cursor-not-allowed';
    }

    return classes;
  });

  const getIconColour = computed(() => {
    switch (props.theme) {
      case 'primary':
      case 'secondary' :
          return 'text-white';
      default :
        return 'text-grey-300';
    }
  });

</script>
